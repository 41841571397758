window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('@popperjs/core');
    window.bootstrap = require('bootstrap');
    require('admin-lte');
    window.Swal = require('sweetalert2');
    window.ApexCharts = require('apexcharts');
    window.moment = require('moment');
    require('daterangepicker');
} catch (e) {
    console.log(e);
}

